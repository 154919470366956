import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { EmailFirstForm } from '../emailFirstForm';
import {
    EMAIL_FIRST_EMAIL_FORM_INPUT_NAME,
    EmailFirstEmailFormInput,
} from '../inputs';
import { useEmailFirstForm } from '../useEmailFirstForm';
import { useSessionStorage } from '../../../../hooks/useSessionStorage';
import { useShowExUkContent } from '../../../../hooks/useShowExUkContent';
import { getEmailFirstRoutes } from '../../../../pages/email-first/emailFirstRouteNames';
import checkEmailUniqueness from '../../../../services/checkEmailUniqueness';
import { useStateContext } from '../../../../modules/stateContext';
import { AgeDeclarationTitle } from './emailFirstEmailForm.style';
import { useConsentAge } from '../../../../context/consentAge';
import renderQueryString from '../../../../../shared/urls/renderQueryString';
import { REGISTRATION_START_EVENT } from '../../../../../shared/data/customEvents/registration';

const UNEXPECTED_ERROR = 'message.gracefulDeg.technicalProblems';

const activeFields = [EMAIL_FIRST_EMAIL_FORM_INPUT_NAME];

export const ONE_TIME_PERSISTENT_USER_IDENTIFIER_KEY =
    'oneTimePersistentUserIdentifierKey';

export const EmailFirstEmailForm = () => {
    const [formErrors, setFormErrors] = useState();
    const history = useHistory();

    const userContext = useStateContext();
    const {
        featureToggles: { consentAgeByCountry, persistentIdentifier },
        isFederated,
        isSingleEntryAuth,
    } = userContext;

    const { emailFirstPaths } = getEmailFirstRoutes(
        isFederated,
        isSingleEntryAuth
    );

    const { sessionStorage } = useSessionStorage();

    const preSubmit = useCallback(async ({ fieldValues }) => {
        setFormErrors(undefined);

        try {
            const { success, message } = await checkEmailUniqueness(
                fieldValues[EMAIL_FIRST_EMAIL_FORM_INPUT_NAME]
            );

            if (!success) {
                if (isSingleEntryAuth) {
                    history.push(
                        `${emailFirstPaths.login.path}${renderQueryString.call({
                            ...userContext,
                            action: 'sign-in',
                        })}`
                    );
                    return false;
                }

                const messageId = message?.email?.id;

                if (
                    persistentIdentifier &&
                    messageId === 'emailFirst.email.duplicate'
                ) {
                    sessionStorage?.setItem(
                        ONE_TIME_PERSISTENT_USER_IDENTIFIER_KEY,
                        fieldValues[EMAIL_FIRST_EMAIL_FORM_INPUT_NAME]
                    );
                }

                const duplicateErrorMessage = isFederated
                    ? message?.federatedEmail
                    : message?.email;

                setFormErrors(duplicateErrorMessage || message);

                return false;
            }
        } catch (error) {
            setFormErrors(UNEXPECTED_ERROR);

            return false;
        }

        return true;
    }, []);

    const {
        fieldValues,
        handleSubmit,
        handleSubmitInvalid,
    } = useEmailFirstForm({
        activeFields,
        nextRoute: emailFirstPaths.password.path,
        preSubmit,
        customEventDataOnSubmit: isSingleEntryAuth
            ? REGISTRATION_START_EVENT
            : undefined,
    });
    const showExUkContent = useShowExUkContent();

    const getHeadingId = useCallback(() => {
        if (isSingleEntryAuth) {
            return 'emailFirst.identifier.title';
        }

        if (showExUkContent) {
            return 'register.title.introductionExUk';
        }

        return 'emailFirst.emailForm.title';
    }, [showExUkContent, isSingleEntryAuth]);

    const { consentAge } = useConsentAge();

    return (
        <EmailFirstForm
            activeFields={activeFields}
            formError={formErrors}
            heading={<FormattedMessage id={getHeadingId()} />}
            hideBackButton
            name="email-first-email-form"
            onSubmit={handleSubmit}
            onSubmitInvalid={handleSubmitInvalid}
            pageId="registration-email-first"
            submitButtonLabel="button.continue.value"
            withSignInCta={!isSingleEntryAuth}
        >
            {showExUkContent && (
                <AgeDeclarationTitle>
                    <FormattedMessage
                        id={
                            consentAgeByCountry
                                ? 'emailFirst.emailForm.ageDeclarationConsentAge.title'
                                : 'emailFirst.emailForm.ageDeclaration.title'
                        }
                        values={{
                            consentAge,
                        }}
                    />
                </AgeDeclarationTitle>
            )}
            <EmailFirstEmailFormInput
                defaultValue={
                    fieldValues &&
                    fieldValues[EMAIL_FIRST_EMAIL_FORM_INPUT_NAME]
                }
            />
        </EmailFirstForm>
    );
};
