import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Button, Heading } from '@bbc-account/id-components';
import { FormattedMessage } from 'react-intl';
import { useStateContext } from '../../../../modules/stateContext';
import usePreventFormResubmission from '../../../../hooks/usePreventFormResubmission';
import Text from '../../../shared/text';
import MagicLinkCta from './magicLinkCta';
import Track from '../../../shared/analytics/track';
import { resolvePageForEvent } from '../../../../../shared/utilities/events';

const MagicLinkCopyVariant = ({
    email,
    onSubmit,
    passwordAction,
    buttonTranslationKey,
}) => {
    const userContext = useStateContext();
    usePreventFormResubmission();

    const {
        location: { isUk },
        signIn,
        featureToggles: { checkHasPassword },
    } = userContext;

    const resolvedPage = useMemo(() => resolvePageForEvent(signIn?.page), [
        signIn?.page,
    ]);
    const hasPassword = signIn?.context?.hasPassword;
    const username = signIn?.data?.username;

    const headingTitle = useMemo(() => {
        if (isUk || checkHasPassword === false || hasPassword === true) {
            return 'Email me a link to sign in';
        }

        return 'auth.magicLink.cta.noPassword.title';
    }, [checkHasPassword, isUk, hasPassword]);

    return (
        <>
            <Track container={resolvedPage} ignoreViews name="submit">
                <Button
                    id="submit-button"
                    isFullWidth
                    isSubmit
                    formAction={passwordAction}
                >
                    <FormattedMessage id={buttonTranslationKey} />
                </Button>
            </Track>
            <div className="u-margin-top-quad u-margin-bottom-penta">
                <Heading fontScale="indexHeadlineMedium" level={2}>
                    <FormattedMessage id={headingTitle} />
                </Heading>
                <Text className="u-margin-top">
                    <FormattedMessage id="We'll send a secure link to the email address you just gave us. You can use that link to sign in without a password." />
                </Text>
                <MagicLinkCta email={email || username} onSubmit={onSubmit} />
            </div>
        </>
    );
};

MagicLinkCopyVariant.propTypes = {
    email: PropTypes.string,
    onSubmit: PropTypes.func,
    passwordAction: PropTypes.string,
    buttonTranslationKey: PropTypes.string,
};

export default MagicLinkCopyVariant;
