import LinkVariant from './linkVariant';
import WhiteButtonVariant from './whiteButtonVariant';
import MagicLinkCopyVariant from './magicLinkCopyVariant';

const experimentVariantComponentMap = {
    white_button: WhiteButtonVariant,
    copy: MagicLinkCopyVariant,
    link: LinkVariant,
};

export default experimentVariantComponentMap;
