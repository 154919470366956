import styled from 'styled-components';
import { Button, Heading } from '@bbc-account/id-components';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useStateContext } from '../../../../modules/stateContext';
import Track from '../../../shared/analytics/track';
import { resolvePageForEvent } from '../../../../../shared/utilities/events';

const StyledHeadingWithLine = styled.div`
    text-align: center;
    width: 100%;
    position: relative;
    overflow: hidden;

    &:before,
    &:after {
        position: absolute;
        top: 51%;
        overflow: hidden;
        width: 45%;
        height: 1px;
        content: '\a0';
        background-color: black;
    }
    &:before {
        margin-left: -50%;
        text-align: right;
    }

    &:after {
        margin-left: 5%;
        text-align: left;
    }
`;

const WhiteButtonVariant = ({
    magicLinkAction,
    passwordAction,
    buttonTranslationKey,
}) => {
    const {
        signIn: {
            page,
            data: { username },
        },
    } = useStateContext();

    const resolvedPage = useMemo(() => resolvePageForEvent(page), [page]);

    return (
        <>
            <Track container={resolvedPage} ignoreViews name="submit">
                <Button
                    id="submit-button"
                    isFullWidth
                    isSubmit
                    formAction={passwordAction}
                >
                    <FormattedMessage id={buttonTranslationKey} />
                </Button>
            </Track>

            <div className="u-margin-top-double u-margin-bottom-double">
                <StyledHeadingWithLine>
                    <Heading level={2} fontScale="indexHeadlineMedium">
                        Or
                    </Heading>
                </StyledHeadingWithLine>
            </div>
            <input
                aria-hidden="true"
                className="u-hide"
                value={username}
                name="email"
            />
            <Track container={resolvedPage} ignoreViews name="submit">
                <Button
                    id="magic-link-submit-button"
                    isFullWidth
                    isSubmit
                    variant="secondary"
                    formAction={magicLinkAction}
                >
                    Email me a link to sign in
                </Button>
            </Track>
        </>
    );
};

WhiteButtonVariant.displayName = 'AuthForm';

WhiteButtonVariant.propTypes = {
    buttonTranslationKey: PropTypes.string,
    magicLinkAction: PropTypes.string,
    passwordAction: PropTypes.string,
};

WhiteButtonVariant.defaultProps = {
    buttonTranslationKey: 'signIn.button.submit.value',
};

export default WhiteButtonVariant;
