import { Button, ButtonInline } from '@bbc-account/id-components';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { useStateContext } from '../../../../modules/stateContext';
import Track from '../../../shared/analytics/track';
import { resolvePageForEvent } from '../../../../../shared/utilities/events';

const LinkVariant = ({
    magicLinkAction,
    passwordAction,
    buttonTranslationKey,
}) => {
    const {
        signIn: {
            page,
            data: { username },
        },
    } = useStateContext();

    const resolvedPage = useMemo(() => resolvePageForEvent(page), [page]);

    return (
        <>
            <div className="u-margin-top-double u-margin-bottom-double">
                <input
                    aria-hidden="true"
                    className="u-hide"
                    value={username}
                    name="email"
                />
                <Track container={resolvedPage} ignoreViews name="submit">
                    Alternative login:{' '}
                    <ButtonInline
                        id="magic-link-submit-button"
                        isSubmit
                        formAction={magicLinkAction}
                    >
                        <FormattedMessage id="Email me a link to sign in" />
                    </ButtonInline>
                </Track>
            </div>

            <Track container={resolvedPage} ignoreViews name="submit">
                <Button
                    id="submit-button"
                    isFullWidth
                    isSubmit
                    formAction={passwordAction}
                >
                    <FormattedMessage id={buttonTranslationKey} />
                </Button>
            </Track>
        </>
    );
};

LinkVariant.displayName = 'AuthForm';

LinkVariant.propTypes = {
    buttonTranslationKey: PropTypes.string,
    magicLinkAction: PropTypes.string,
    passwordAction: PropTypes.string,
};

LinkVariant.defaultProps = {
    buttonTranslationKey: 'signIn.button.submit.value',
};

export default LinkVariant;
