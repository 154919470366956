import React from 'react';
import PropTypes from 'prop-types';

import { FormStateProvider, useFormState } from '@bbc-account/id-formaxe';
import { useStateContext } from '../../../../modules/stateContext';
import FormError from '../../../shared/formError';
import renderQueryString from '../../../../../shared/urls/renderQueryString';
import userJourneys from '../../../../../shared/data/userJourneys';
import {
    AUTH_PASSWORD_PATH,
    AUTH_PATH,
} from '../../../../../shared/endpoints/account';
import experimentVariantComponentMap from './experimentVariantComponentMap';
import useJsEnabled from '../../../../hooks/useJsEnabled';

const ExperimentAuthForm = ({ children, buttonTranslationKey, variant }) => {
    const userContext = useStateContext();
    const jsEnabled = useJsEnabled();

    const {
        signIn: { initialErrors, initialValues },
    } = userContext;

    const formState = useFormState({
        initialValues,
        initialErrors,
    });

    const showGeneralErrors = Object.keys(formState.fieldValues).every(
        key => formState.fieldValues[key] === initialValues[key]
    );

    const ExperimentVariationComponent = experimentVariantComponentMap[variant];

    const magicLinkAction = `${AUTH_PATH}${renderQueryString.call(userContext, {
        userJourney: userJourneys.MAGIC_LINK,
    })}`;

    const passwordAction = `${AUTH_PASSWORD_PATH}${renderQueryString.call(
        userContext,
        jsEnabled === false ? { jsEnabled: 'false' } : {}
    )}`;

    return (
        <>
            <FormError
                error={showGeneralErrors && initialErrors.general}
                name="general"
                migrateToIdComponents
                isAssertive
            />
            <form method="post" noValidate>
                <FormStateProvider value={formState}>
                    {children}
                </FormStateProvider>

                <ExperimentVariationComponent
                    magicLinkAction={magicLinkAction}
                    passwordAction={passwordAction}
                    buttonTranslationKey={buttonTranslationKey}
                />
            </form>
        </>
    );
};

ExperimentAuthForm.displayName = 'AuthForm';

ExperimentAuthForm.propTypes = {
    action: PropTypes.string,
    children: PropTypes.node,
    buttonTranslationKey: PropTypes.string,
    variant: PropTypes.oneOf(Object.keys(experimentVariantComponentMap)),
};

ExperimentAuthForm.defaultProps = {
    buttonTranslationKey: 'signIn.button.submit.value',
};

export default ExperimentAuthForm;
