import { sendCustomEvent } from '../sendCustomEvent';
import { setupPianoEventData } from './setupPianoEventData';

type SendCustomEventOptions = Parameters<typeof sendCustomEvent>[0];

type SetupPianoEventDataOptions = Parameters<typeof setupPianoEventData>[0];

type SendPianoCustomEventErrorHandler = (
    message: string,
    eventName: string
) => void;

type SendPianoCustomEventSuccessHandler = (
    message: string,
    errorMessage: string
) => void;

type SendPianoCustomEventOptions = SetupPianoEventDataOptions &
    Omit<SendCustomEventOptions, 'baseUrl' | 'event'> & {
        baseUrl?: SendCustomEventOptions['baseUrl'];
        onError?: SendPianoCustomEventErrorHandler;
        onSuccess?: SendPianoCustomEventSuccessHandler;
    };

const PIANO_BASE_URL = 'https://a1.api.bbc.co.uk/hit.xiti';

export async function sendPianoCustomEvent({
    baseUrl,
    clientId,
    data,
    generateUserOriginFromPtrt,
    isFederated,
    name,
    onError,
    onSuccess,
    ptrt,
    realm,
    sequenceId,
    userOrigin,
    ...options
}: SendPianoCustomEventOptions) {
    const event = setupPianoEventData({
        clientId,
        data,
        generateUserOriginFromPtrt,
        name,
        ptrt,
        sequenceId,
        isFederated,
        realm,
        userOrigin,
    });

    try {
        await sendCustomEvent({
            baseUrl: typeof baseUrl === 'undefined' ? PIANO_BASE_URL : baseUrl,
            event,
            ...options,
        });

        onSuccess?.('Successfully send piano event', event.name);

        return true;
    } catch (error) {
        let errorMessage = error;

        if (typeof error === 'object' && error && 'message' in error) {
            errorMessage = error.message;
        }

        onError?.('Piano service error', String(errorMessage));
    }

    return false;
}
