import React, { useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import AuthForm from '../form';
import Layout from '../../pageLayout/layout';
import PasswordInput from '../inputs/password';
import renderQueryString from '../../../../shared/urls/renderQueryString';
import { useStateContext } from '../../../modules/stateContext';
import useJsEnabled from '../../../hooks/useJsEnabled';
import {
    AUTH_PASSWORD_PATH,
    CREATE_PASSWORD_PATH,
    FORGOTTEN_PASSWORD_PATH,
} from '../../../../shared/endpoints/account';
import { HelpLinks } from '../helpLinks';
import { useSessionStorage } from '../../../hooks/useSessionStorage';
import { ONE_TIME_PERSISTENT_USER_IDENTIFIER_KEY } from '../../forms';
import { MagicLinkCopy } from './magicLinkCopy';
import { useExperiment } from '../../../experiments/useExperiment';
import experimentVariantComponentMap from '../experiments/magicLinkUx/experimentVariantComponentMap';
import ExperimentAuthForm from '../experiments/magicLinkUx/experimentAuthForm';

const forgottenPasswordLink = {
    href: FORGOTTEN_PASSWORD_PATH,
    label: 'cama.link.forgotPassword',
};

const createPasswordLink = {
    href: CREATE_PASSWORD_PATH,
    label: 'auth.password.create.label',
};

const PasswordForm = () => {
    const userContext = useStateContext();
    const jsEnabled = useJsEnabled();

    const {
        location: { isUk },
        signIn: {
            context: { hasPassword, magicLinkEnabled },
            data,
        },
        featureToggles: {
            checkHasPassword,
            createPasswordContent,
            persistentIdentifier,
            magicLinksUxExperiment,
            magicLinks,
        },
    } = userContext;

    const { sessionStorage } = useSessionStorage();

    const shouldHideResetPasswordLink = magicLinkEnabled;

    useEffect(() => {
        if (persistentIdentifier && data?.username) {
            sessionStorage?.setItem(
                ONE_TIME_PERSISTENT_USER_IDENTIFIER_KEY,
                data.username
            );
        }
    }, []);

    const helpLinksItems = useMemo(() => {
        const items = [];

        if (isUk) {
            if (!shouldHideResetPasswordLink) {
                items.push(forgottenPasswordLink);
            }
        } else if (!checkHasPassword || hasPassword) {
            items.push(forgottenPasswordLink);
        } else if (createPasswordContent) {
            items.push(createPasswordLink);
        }

        return items;
    }, [
        checkHasPassword,
        createPasswordContent,
        hasPassword,
        isUk,
        shouldHideResetPasswordLink,
    ]);

    const passwordAction = `${AUTH_PASSWORD_PATH}${renderQueryString.call(
        userContext,
        jsEnabled === false ? { jsEnabled: 'false' } : {}
    )}`;

    const magicLinkExperimentVariant = useExperiment(
        'accwb_ML_design_change_1'
    );

    const magicLinkExperimentEnabled = magicLinks && magicLinksUxExperiment;
    const experimentVariantSupported = !!experimentVariantComponentMap[
        magicLinkExperimentVariant
    ];
    const shouldShowExperiment =
        magicLinkExperimentEnabled && experimentVariantSupported;

    return (
        <Layout
            pageId="signin-page"
            heading={<FormattedMessage id="auth.password.title.introduction" />}
            showServiceIdentifiers
        >
            {shouldShowExperiment ? (
                <ExperimentAuthForm
                    buttonTranslationKey="signIn.button.submit.value"
                    variant={magicLinkExperimentVariant}
                >
                    <input
                        name="hasPassword"
                        value={hasPassword}
                        type="hidden"
                    />
                    <PasswordInput />
                </ExperimentAuthForm>
            ) : (
                <>
                    <AuthForm
                        action={passwordAction}
                        buttonTranslationKey="signIn.button.submit.value"
                    >
                        <input
                            name="hasPassword"
                            value={hasPassword}
                            type="hidden"
                        />
                        <PasswordInput />
                    </AuthForm>
                    {magicLinkEnabled && <MagicLinkCopy />}
                </>
            )}

            <HelpLinks className="button__label" items={helpLinksItems} />
        </Layout>
    );
};

export default PasswordForm;
