import { getAppName } from './getAppName';
import { getAppType } from './getAppType';

type GetAppNameOptions = Parameters<typeof getAppName>[0];

type GetAppTypeOptions = Parameters<typeof getAppType>[0];

type Event = Record<string, unknown> & {
    sequence_id?: string;
};

type SetupPianoEventData = {
    event: Event;
};

type SetupPianoEventDataOptions = GetAppNameOptions &
    GetAppTypeOptions & {
        data: SetupPianoEventData;
        name: string;
        sequenceId?: string;
    };

export function setupPianoEventData({
    clientId,
    data,
    generateUserOriginFromPtrt,
    isFederated,
    name,
    ptrt,
    realm,
    sequenceId,
    userOrigin,
}: SetupPianoEventDataOptions) {
    const { event } = data;

    if (typeof sequenceId !== 'undefined') {
        event.sequence_id = sequenceId;
    }

    return {
        data: {
            ...data,
            app: {
                name: getAppName({
                    clientId,
                    generateUserOriginFromPtrt,
                    ptrt,
                    userOrigin,
                }),
                type: getAppType({
                    isFederated,
                    realm,
                }),
            },
            event,
        },
        name,
    };
}
