import React from 'react';
import PropTypes from 'prop-types';
import { Button, CallToActionButton } from '@bbc-account/id-components';
import { FormattedMessage } from 'react-intl';
import renderQueryString from '../../../../../shared/urls/renderQueryString';
import userJourneys from '../../../../../shared/data/userJourneys';
import { AUTH_PATH } from '../../../../../shared/endpoints/account';
import { useStateContext } from '../../../../modules/stateContext';
import Track from '../../../shared/analytics/track';

const MagicLinkCta = ({ email, onSubmit }) => {
    const userContext = useStateContext();
    const magicLinkHref = `${AUTH_PATH}${renderQueryString.call(userContext, {
        userJourney: userJourneys.MAGIC_LINK,
    })}`;

    return email ? (
        <form
            action={magicLinkHref}
            method="POST"
            data-testid="magic-link-cta"
            onSubmit={onSubmit}
        >
            <input
                aria-hidden="true"
                className="u-hide"
                value={email}
                name="email"
            />

            <Track container="magic-link" ignoreViews name="magic-link-cta">
                <Button isFullWidth isSubmit variant="secondary">
                    <FormattedMessage id="auth.magicLink.cta.button" />
                </Button>
            </Track>
        </form>
    ) : (
        <Track container="magic-link" ignoreViews name="magic-link-cta">
            <CallToActionButton
                isFullWidth
                variant="secondary"
                href={magicLinkHref}
            >
                <FormattedMessage id="Send me a link" />
            </CallToActionButton>
        </Track>
    );
};

MagicLinkCta.propTypes = {
    email: PropTypes.string,
    onSubmit: PropTypes.func,
};

export default MagicLinkCta;
